import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';
import resolveConfig from 'tailwindcss/resolveConfig';

import Layout from '../layouts/layout';
import CommonBanner from '../components/commonBanner';
import BlogCard from '../components/blog-card';
import Link from '../components/Link';
import MetaTags from '../components/SiteMeta';
import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const BlogListStyled = styled.section`
  ${tw`block relative`};
  button:focus {
    outline: none;
  }
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col md:flex-row md:justify-between md:items-center relative items-center text-center mb-7`};

  h2 {
    ${tw`mb-2 text-primary-dark`}
  }

  .filters {
    ${tw`font-normal text-primary-dark w-full relative pl-2`};

    @media (min-width: ${fullConfig.theme.screens.md}) {
      ${tw`w-auto`};
      max-width: 545px;
    }

    @media (min-width: ${fullConfig.theme.screens.xl}) {
      max-width: 670px;
    }

    ul {
      ${tw`flex overflow-x-auto lg:pb-2`};
      padding-right: 65px;
      padding-left: 5px;

      &::after {
        content: '';
        ${tw`absolute top-0 right-0 h-full`};
        width: 70px;
        z-index: 1;
        background: transparent linear-gradient(91deg, #ffffff34 0%, #ffffff5f 33%, #ffffff 100%) 0%
          0% no-repeat padding-box;
      }

      li {
        ${tw`uppercase text-primary-dark font-semibold ml-5 lg:ml-7 text-xs lg:text-sm xl:text-base `};
        min-width: fit-content;
        cursor: pointer;

        &:first-child {
          ${tw`ml-0`};
        }
      }

      @media (min-width: ${fullConfig.theme.screens.lg}) {
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        &::-webkit-scrollbar-track {
          ${tw`bg-gray-lighter rounded-xl`}
        }

        &::-webkit-scrollbar-thumb {
          ${tw`bg-gray-light rounded-xl`}
        }
      }
    }
  }
`;

export const FeaturedArticleSection = styled.div`
  ${tw`relative`};

  .featured-article-img-col {
    .gatsby-image-wrapper {
      ${tw`w-full h-full`};
    }
  }

  .featured-article-content-col {
    h6 {
      ${tw`uppercase text-gray-darker font-bold mb-3`};
    }

    h3 {
      ${tw`text-primary-dark text-2xl lg:text-3xl font-bold mb-5`};
    }

    p {
      ${tw`lg:text-xl text-gray-dark mb-7 lg:mb-10 lg:leading-8`};
    }

    .more {
      ${tw`uppercase font-bold`};
      letter-spacing: 1.6px;
    }
  }
`;

// markup
export default class BlogsPage extends React.Component {
  constructor(props) {
    super(props);
    const postsToShow = 6;
    this.state = {
      postsToShow,
      filteredPosts: [],
      selectedCat: '',
    };
  }

  render() {
    const allPosts = this.props.data.allWpPost?.nodes;
    const categories = this.props.data.categories?.nodes;
    const index = this.state.postsToShow;
    const { postsToShow, filteredPosts, selectedCat } = this.state;
    const { acfGlobalContent } = this.props.data?.page?.nodes[0];
    const { acfBlogListing } = this.props.data?.page?.nodes[0];
    const featuredArticles = [];
    allPosts.map((item) =>
      item?.acfBlogDetail?.featuredArticle ? featuredArticles?.push(item) : '',
    );
    let items = [];
    let renderFilteredPost = [];
    let filteringPost;

    if (selectedCat) {
      filteringPost = allPosts.map((item) => {
        items = item.categories.nodes.map((node) => node.name);
        return items.includes(selectedCat) ? item : null;
      });
      renderFilteredPost = filteringPost.filter((item) => item !== null);
    }

    return (
      <Layout preFooterContent={acfGlobalContent} headerSpecificClass="blog-listing">
        <MetaTags
          title={acfGlobalContent.metaTitle}
          description={acfGlobalContent.metaDescription}
          image={acfGlobalContent.metaOgImage?.sourceUrl}
        />
        <main>
          <CommonBanner
            title={acfBlogListing?.pageTitle}
            subtitle={acfBlogListing?.subtitle}
            noBannerImage
          />

          {featuredArticles?.length !== 0 && (
            <FeaturedArticleSection className="featured-article-section ves-section">
              <div className="ves-container">
                <div className="featured-article" key={featuredArticles[0].id}>
                  <Link
                    to={featuredArticles[0].uri}
                    className="grid grid-cols-1 md:grid-cols-12 md:gap-12">
                    <div className="featured-article-img-col md:col-span-6">
                      {featuredArticles[0].featuredImage && (
                        <GatsbyImage
                          image={getImage(featuredArticles[0].featuredImage?.node.localFile)}
                          alt={featuredArticles[0].title}
                        />
                      )}
                    </div>
                    <div className="featured-article-content-col md:col-span-6">
                      <h6>{featuredArticles[0].date}</h6>
                      <h3 className="h3">{featuredArticles[0].title}</h3>
                      <p className="p">{featuredArticles[0].acfBlogDetail.briefDescription}</p>
                      <span className="more">Read more</span>
                    </div>
                  </Link>
                </div>
              </div>
            </FeaturedArticleSection>
          )}

          <BlogListStyled>
            <div className="ves-container">
              <SectionHeaderStyled>
                <h2 className="h2">Filter stories</h2>
                <div className="filters">
                  <ul>
                    {categories?.map((item) => (
                      <li
                        key={item.id}
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({
                            selectedCat: item.name,
                          });
                          allPosts.filter((post) => {
                            const cats = post.categories.nodes.map((node) => node.name);
                            return cats.includes(item.name);
                          });
                        }}>
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </SectionHeaderStyled>

              <div className="grid  md:grid-cols-3 gap-4 md:gap-y-16">
                {renderFilteredPost?.length > 0
                  ? renderFilteredPost
                      ?.slice(0, index)
                      ?.map((item) => (
                        <BlogCard
                          key={uuidv4()}
                          date={item?.date}
                          title={item?.title}
                          description={item?.acfBlogDetail.briefDescription}
                          link={item?.uri}
                          image={item?.featuredImage?.node.localFile}
                        />
                      ))
                  : allPosts
                      ?.slice(0, index)
                      ?.map((item) => (
                        <BlogCard
                          key={uuidv4()}
                          date={item?.date}
                          title={item?.title}
                          description={item?.acfBlogDetail.briefDescription}
                          link={item?.uri}
                          image={item?.featuredImage?.node.localFile}
                        />
                      ))}
              </div>

              {renderFilteredPost?.length > 0
                ? postsToShow < renderFilteredPost?.length && (
                    <div className="load-more-wrap mt-12">
                      <button
                        type="button"
                        className="btn btn-primary m-auto outline-none"
                        onClick={() => {
                          this.setState({
                            postsToShow: postsToShow + 6,
                          });
                        }}>
                        Load More
                      </button>
                    </div>
                  )
                : postsToShow < this.props.data.allWpPost?.nodes?.length && (
                    <div className="load-more-wrap mt-12">
                      <button
                        type="button"
                        className="btn btn-primary m-auto outline-none"
                        onClick={() => {
                          this.setState({
                            postsToShow: postsToShow + 6,
                          });
                        }}>
                        Load More
                      </button>
                    </div>
                  )}
            </div>
          </BlogListStyled>
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query BlogListing {
    page: allWpPage(filter: { slug: { eq: "blog-listing" } }) {
      nodes {
        acfGlobalContent {
          prefooterTitle
          prefooterButtonText
          prefooterButtonLink {
            url
          }
          metaTitle
          metaDescription
          metaOgImage {
            sourceUrl
          }
        }
        acfBlogListing {
          pageTitle
          subtitle
        }
      }
    }
    allWpPost(sort: { order: DESC, fields: date }) {
      nodes {
        id
        title
        uri
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, height: 252, placeholder: BLURRED, quality: 90)
              }
            }
          }
        }
        categories {
          nodes {
            name
            id
          }
        }
        acfBlogDetail {
          briefDescription
          featuredArticle
        }
      }
    }
    categories: allWpCategory(filter: { name: { ne: "Uncategorized" } }) {
      nodes {
        name
        id
      }
    }
  }
`;

BlogsPage.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.any),
    }),
  }).isRequired,
};
